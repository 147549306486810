<template>
    <div class="question-item">
        <h2 :style="{ color: pageInfo.style.questionColor }">{{title}}</h2>
        <div>
            <Input :style="{ backgroundColor: pageInfo.style.controlBgColor }" long />
        </div>
    </div>
</template>
<script>
export default {
    name: "UrlParameter",
    props: ["title",  "parameterKey", "display", "pageInfo"]
}
</script>